import React from "react";

// Component imports

export default class AppFAQFullItem extends React.Component {
  render() {
    let postDate = new Date(this.props.date);
    return (
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 text-white">
            <div className="app-faq-item-title">{this.props.title}</div>
            <span className="text-muted">{postDate.toLocaleDateString()}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="text-white app-faq-item-text"
              dangerouslySetInnerHTML={{ __html: this.props.content }}
            />
          </div>
        </div>
      </div>
    );
  }
}
